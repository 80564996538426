import React, {useState} from 'react';
import {Alert, Button, MenuItem, TextField} from '@mui/material';
import {createSocio, updateSocio} from '../../utility/Api';

function SocioForm({socio, onSave}) {
    const [formData, setFormData] = useState(socio || {});
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);

    const handleChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setSuccess(null);

        const processError = (error) => {
            if (error.response.status === 422) {
                const errors = error.response.data.detail || [];
                const errorMessages = errors.map(err => `${err.loc.join(' -> ')}: ${err.msg}`).join('\n');
                setError(`Errore nel modulo:\n${errorMessages}`);
            } else {
                setError('Failed to save socio: ' + (error.message || 'Unknown error'));
            }
        };

        if (socio) {
            updateSocio(socio.id, formData)
                .then(() => {
                    setSuccess('Socio aggiornato correttamente.');
                    onSave();
                })
                .catch(processError);
        } else {
            createSocio(formData)
                .then(() => {
                    setSuccess('Socio salvato correttamente.');
                })
                .catch(processError);
        }
    };

    const handleCopyAddress = () => {
        const updatedFormData = {
            ...formData,
            indirizzo_res_gen_1: formData.indirizzo_res,
            comune_res_gen_1: formData.comune_res,
            cap_gen_1: formData.cap,
            indirizzo_res_gen_2: formData.indirizzo_res,
            comune_res_gen_2: formData.comune_res,
            cap_gen_2: formData.cap,
        };
        setFormData(updatedFormData);
    };

    const isPersonaGiuridica = formData.sesso === 'Persona Giuridica';

    return (
        <form onSubmit={handleSubmit}>
            {success && <Alert severity="success">{success}</Alert>}
            {error &&
                <Alert severity="error">
                    <pre style={{margin: 0}}>{error}</pre>
                </Alert>
            }
            <TextField
                label="Nome"
                name="nome"
                value={formData.nome || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                label="Cognome"
                name="cognome"
                value={formData.cognome || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                select
                label="Sesso"
                name="sesso"
                value={formData.sesso || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            >
                <MenuItem value="Maschio">Maschio</MenuItem>
                <MenuItem value="Femmina">Femmina</MenuItem>
                <MenuItem value="Persona Giuridica">Persona Giuridica</MenuItem>
            </TextField>
            <TextField
                label="Codice Fiscale"
                name="cf"
                value={formData.cf || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                label="Indirizzo Residenza"
                name="indirizzo_res"
                value={formData.indirizzo_res || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                type="number"
                label="CAP"
                name="cap"
                value={formData.cap || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                label="Comune Residenza"
                name="comune_res"
                value={formData.comune_res || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                label="Provincia Residenza"
                name="provincia_res"
                value={formData.provincia_res || ''}
                onChange={handleChange}
                required
                margin="normal"
                fullWidth
            />
            <TextField
                label="Telefono fisso"
                name="fisso"
                value={formData.fisso || ''}
                onChange={handleChange}
                margin="normal"
                fullWidth
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleCopyAddress}
                fullWidth
                margin="normal"
                disabled={isPersonaGiuridica}
            >
                Copia residenza del figlio anche per entrambi i genitori
            </Button>
            {!isPersonaGiuridica && (
                <>
                    <TextField
                        label="Luogo di Nascita"
                        name="luogo_di_nascita"
                        value={formData.luogo_di_nascita || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        type="date"
                        label="Data di Nascita"
                        name="data_di_nascita"
                        value={formData.data_di_nascita || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        InputLabelProps={{shrink: true}}
                        fullWidth
                    />
                    <TextField
                        select
                        label="Sede"
                        name="sede"
                        value={formData.sede || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    >
                        <MenuItem value="Brescia">Brescia</MenuItem>
                        <MenuItem value="Milano">Milano</MenuItem>
                        <MenuItem value="Online">Online</MenuItem>
                    </TextField>
                    <TextField
                        label="Nome Genitore 1"
                        name="nome_gen_1"
                        value={formData.nome_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Cognome Genitore 1"
                        name="cognome_gen_1"
                        value={formData.cognome_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Cellulare (senza prefisso) Genitore 1"
                        name="cellulare_gen_1"
                        value={formData.cellulare_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Email Genitore 1"
                        name="email_gen_1"
                        type="email"
                        value={formData.email_gen_1 || ''}
                        required
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Luogo di Nascita Genitore 1"
                        name="luogo_di_nascita_gen_1"
                        value={formData.luogo_di_nascita_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        type="date"
                        label="Data di Nascita Genitore 1"
                        name="data_di_nascita_gen_1"
                        value={formData.data_di_nascita_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        InputLabelProps={{shrink: true}}
                        fullWidth
                    />
                    <TextField
                        label="Codice Fiscale Genitore 1"
                        name="cf_gen_1"
                        value={formData.cf_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Comune Residenza Genitore 1"
                        name="comune_res_gen_1"
                        value={formData.comune_res_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Indirizzo Residenza Genitore 1"
                        name="indirizzo_res_gen_1"
                        value={formData.indirizzo_res_gen_1 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Nome Genitore 2"
                        name="nome_gen_2"
                        value={formData.nome_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Cognome Genitore 2"
                        name="cognome_gen_2"
                        value={formData.cognome_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Cellulare (senza prefisso) Genitore 2"
                        name="cellulare_gen_2"
                        value={formData.cellulare_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Email Genitore 2"
                        name="email_gen_2"
                        type="email"
                        value={formData.email_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Luogo di Nascita Genitore 2"
                        name="luogo_di_nascita_gen_2"
                        value={formData.luogo_di_nascita_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        type="date"
                        label="Data di Nascita Genitore 2"
                        name="data_di_nascita_gen_2"
                        value={formData.data_di_nascita_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        InputLabelProps={{shrink: true}}
                        fullWidth
                    />
                    <TextField
                        label="Codice Fiscale Genitore 2"
                        name="cf_gen_2"
                        value={formData.cf_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Comune Residenza Genitore 2"
                        name="comune_res_gen_2"
                        value={formData.comune_res_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Indirizzo Residenza Genitore 2"
                        name="indirizzo_res_gen_2"
                        value={formData.indirizzo_res_gen_2 || ''}
                        onChange={handleChange}
                        required
                        margin="normal"
                        fullWidth
                    />
                </>
            )}
            <Button type="submit" variant="contained" color="primary">
                Aggiungi Socio
            </Button>
        </form>
    );
}

export default SocioForm;
