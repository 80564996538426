import React from 'react';
import Navbar from './Navbar';

const BasePage = ({ children,  titolo }) => {
    return (
        <>
            <Navbar titolo={titolo}></Navbar>
            <div className="content">
                {children}
            </div>
        </>
    );
};

export default BasePage;
