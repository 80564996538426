import React from 'react';
import Socio from '../components/soci/Socio';
import BasePage from "../components/BasePage";

function SocioPage() {
    return (
        <BasePage titolo="Socio">
            <Socio/>
        </BasePage>
    );
}

export default SocioPage;
