import axios from 'axios';

// Set the API URL based on the environment
let API_URL;
if (process.env.NODE_ENV === 'development') {
    API_URL = 'http://localhost:8000';
} else {
    API_URL = 'https://backend-gestionale-dreampuzzle.codeflow.it';
}

// Helper function to get the token from localStorage
const getAuthToken = () => {
    return localStorage.getItem('token');
}

// Set the Authorization header for authenticated requests
const authHeaders = () => {
    const token = getAuthToken();
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    };
}

// API functions
export const fetchSoci = async () => {
    return await axios.get(`${API_URL}/soci/`, authHeaders());
};

export const fetchSocio = async (id) => {
    const response = await axios.get(`${API_URL}/soci/${id}`, authHeaders());
    return response.data;
};

export const createSocio = async (data) => {
    const response = await axios.post(`${API_URL}/soci`, data, authHeaders());
    return response.data;
};

export const login = async (username, password) => {
    const response = await axios.post(`${API_URL}/login/`, {
        username,
        password,
    });
    return response.data;
};

export const updateSocio = async (id, data) => {
    const response = await axios.patch(`${API_URL}/soci/${id}`, data, authHeaders());
    return response.data;
};

export const fetchRicevuta = async (id) => {
    const response = await axios.get(`${API_URL}/ricevute/${id}`, authHeaders());
    return response.data;
};

export const createRicevuta = async (data) => {
    const response = await axios.post(`${API_URL}/ricevute/`, data, authHeaders());
    return response.data;
};

export const updateRicevuta = async (id, data) => {
    const response = await axios.patch(`${API_URL}/ricevute/${id}`, data, authHeaders());
    return response.data;
};

export const downloadRicevuta = async (id) => {
    const response = await axios.get(`${API_URL}/ricevute/genera/${id}`, authHeaders());
    return response.data;
};

export const fetchRicevute = async (join = false) => {
    return await axios.get(`${API_URL}/ricevute`, {
        params: {
            join: join
        },
        ...authHeaders()
    });
};

export const deleteRicevuta = async (id) => {
    return await axios.delete(`${API_URL}/ricevute/${id}`, authHeaders());
};
