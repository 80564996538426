import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from "../components/BasePage";
import { login } from "../utility/Api";
import { Alert, Button, TextField } from "@mui/material";

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Reset the error state before a new attempt
        try {
            const data = await login(username, password); // Call the login API
            localStorage.setItem('token', data.access_token); // Store JWT token in local storage
            navigate('/'); // Redirect to home or a protected route
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError('Credenziali errate. Riprova.');
            } else {
                setError('Errore durante il login. Per favore riprova più tardi.');
            }
        }
    };

    return (
        <BasePage titolo="Login">
            <form onSubmit={handleSubmit} id="login-form">
                <TextField
                    label="Nome Utente"
                    margin="normal"
                    type="text"
                    placeholder="Nome Utente"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    required
                />
                <TextField
                    label="Password"
                    margin="normal"
                    type="password"
                    placeholder="password-sicura"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Login
                </Button>
            </form>
            {error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}
        </BasePage>
    );
}

export default LoginPage;
