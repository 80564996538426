import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RicevutaListPage from './pages/RicevutaListPage';
import RicevutaFormPage from './pages/RicevutaFormPage';
import SocioFormPage from './pages/SocioFormPage';
import SocioListPage from './pages/SocioListPage';
import Home from './pages/Home';
import NotFoundPage from './pages/404';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import './style.css';
import SocioPage from './pages/SocioPage';

function App() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" exact element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/socio" exact element={<PrivateRoute><SocioPage /></PrivateRoute>} />
            <Route path="/soci" exact element={<PrivateRoute><SocioListPage /></PrivateRoute>} />
            <Route path="/soci/edit" element={<PrivateRoute><SocioFormPage /></PrivateRoute>} />
            <Route path="/soci/create" element={<SocioFormPage/>} />
            <Route path="/ricevute" exact element={<PrivateRoute><RicevutaListPage /></PrivateRoute>} />
            <Route path="/ricevute/edit" element={<PrivateRoute><RicevutaFormPage /></PrivateRoute>} />
            <Route path="/ricevute/create" element={<PrivateRoute><RicevutaFormPage /></PrivateRoute>} />
            <Route path="/*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default App;
