import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/');
    };

    return (
        <div id="not-found-page">
            <h1>404 - Page Not Found</h1>
            <p>La pagina che stai cercando non esiste</p>
            <button onClick={handleNavigation} className="btn-go">
                Vai alla home
            </button>
        </div>
    );
}

export default NotFoundPage;
