import React from 'react';
import {Link} from 'react-router-dom';
import BasePage from "../components/BasePage";

const Home = () => {
    return (
        <BasePage titolo="Gestionale Dreampuzzle">
            <menu>
                <ul>
                    <li><Link to="/soci">Lista Soci</Link></li>
                    <li><Link to="/soci/create">Crea Socio</Link></li>
                    <li><Link to="/ricevute">Lista Ricevute</Link></li>
                    <li><Link to="/ricevute/create">Crea Ricevuta</Link></li>
                    <li><Link to="/socio">Visualizza le ricevute di un socio</Link></li>
                </ul>
            </menu>
        </BasePage>
    );
};

export default Home;

