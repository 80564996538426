import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSoci } from '../../utility/Api';
import { List, ListItem, ListItemText, Button } from '@mui/material';

function SocioList() {
    const [soci, setSoci] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSoci()
            .then(response => setSoci(response.data))
            .catch(error => console.error('Error fetching soci:', error));
    }, []);

    const handleGenerateRicevuta = (socio) => {
        navigate('/ricevute/create', { state: { socio } });
    };

    return (
        <List>
            {soci.map(socio => (
                <ListItem key={socio.id}>
                    <ListItemText  primary={`${socio.nome} ${socio.cognome} - ${socio.cf}`} secondary={socio.email_gen_1} />
                    <Button variant="contained" color="primary" onClick={() => handleGenerateRicevuta(socio)}>
                        Genera Ricevuta
                    </Button>
                </ListItem>
            ))}
        </List>
    );
}

export default SocioList;
