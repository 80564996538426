import React, { useEffect, useState } from "react";
import { fetchSoci } from "../../utility/Api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import RicevutaList from "../ricevute/RicevutaList";

function Socio() {
    const [soci, setSoci] = useState([]);
    const [selectedSocio, setSelectedSocio] = useState(1);

    useEffect(() => {
        fetchSoci()
            .then(response => setSoci(response.data))
            .catch(error => console.error('Error fetching soci:', error));
    }, []);

    const handleChange = (event) => {
        setSelectedSocio(event.target.value);
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="socio-id-label">Socio</InputLabel>
                <Select
                    labelId="socio-id-label"
                    id="socio_id"
                    name="socio_id"
                    value={selectedSocio}
                    onChange={handleChange}
                    fullWidth
                >
                    {soci.map(socio => (
                        <MenuItem key={socio.id} value={socio.id}>
                            {socio.nome} {socio.cognome}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <RicevutaList socio_id={selectedSocio}></RicevutaList>
        </>
    );
}

export default Socio;
