import React from 'react';
import RicevutaForm from '../components/ricevute/RicevutaForm';
import BasePage from "../components/BasePage";
import { useLocation } from 'react-router-dom';

function RicevutaFormPage() {
    const location = useLocation();
    const {socio} = location.state || "";
    const {ricevuta_id} = location.state || "";
    return (
        <BasePage titolo="Ricevuta">
            <RicevutaForm socio={socio} ricevuta_id={ricevuta_id}/>
        </BasePage>
    );
}

export default RicevutaFormPage;
