import React from 'react';
import RicevutaList from '../components/ricevute/RicevutaList';
import BasePage from "../components/BasePage";

function RicevutaListPage() {
    return (
        <BasePage titolo="Lista Ricevute">
            <RicevutaList />
        </BasePage>
    );
}

export default RicevutaListPage;
