import React, {useCallback, useEffect, useState} from 'react';
import {deleteRicevuta, fetchRicevute, fetchSoci} from '../../utility/Api';
import {
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PrintIcon from '@mui/icons-material/Print';

function RicevutaList({socio_id }) {
    const navigate = useNavigate();
    const [ricevute, setRicevute] = useState([]);
    const [filteredRicevute, setFilteredRicevute] = useState([]);
    const [selectedType, setSelectedType] = useState('Tesseramento');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [soci, setSoci] = useState([]);

    useEffect(() => {
        fetchRicevute()
            .then(response => setRicevute(response.data))
            .catch(error => console.error('Error fetching ricevute:', error));
    }, []);
    const generatePDF = async () => {
        const doc = new jsPDF();
        const columns = ['Nome', 'Cognome', 'Da pagare', 'Tipo', 'Numero', 'Data'];

        try {
            const rows = filteredRicevute.map(ricevuta => {
                const numero = ricevuta.tipo === "Tesseramento" ? ricevuta.numero_ric : ricevuta.numero_liberale;
                return [ricevuta.socio.nome, ricevuta.socio.cognome, ricevuta.da_pagare, ricevuta.tipo, numero, ricevuta.data];
            });

            doc.text('Ricevute', 20, 10);
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 20
            });
            doc.save('ricevute_selezionate.pdf');
        } catch (error) {
            console.error('Error fetching ricevute:', error);
        }
    };
    const filterRicevute = useCallback(() => {
        let filtered = ricevute;
        if (selectedType === 'Erogazioni Liberali') {
            filtered = filtered.filter(ricevuta => ricevuta.numero_liberale != null);
        } else if (selectedType === 'Tesseramento') {
            filtered = filtered.filter(ricevuta => ricevuta.numero_ric != null);
        }
        if (selectedPaymentStatus === 'Pagate') {
            filtered = filtered.filter(ricevuta => ricevuta.incassata === true);
        } else if (selectedPaymentStatus === 'Non pagate') {
            filtered = filtered.filter(ricevuta => ricevuta.incassata === false);
        }
        if (socio_id) {
            filtered = filtered.filter(ricevuta => ricevuta.socio_id === socio_id);
        }
        setFilteredRicevute(filtered);
    }, [ricevute, selectedType, selectedPaymentStatus, socio_id]);

    useEffect(() => {
        filterRicevute();
    }, [selectedType, selectedPaymentStatus, ricevute, filterRicevute]);

    const handleDelete = async (id) => {
        try {
            await deleteRicevuta(id);
            setRicevute(prevRicevute => prevRicevute.filter(ricevuta => ricevuta.id !== id));
        } catch (error) {
            console.error('Error deleting ricevuta:', error);
        }
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const handlePaymentStatusChange = (event) => {
        setSelectedPaymentStatus(event.target.value);
    };

    useEffect(() => {
        fetchSoci()
            .then(response => setSoci(response.data))
            .catch(error => console.error('Error fetching soci:', error));
    }, []);
    return (
        <div >
            <IconButton aria-label="print" onClick={() => generatePDF()}>
                <PrintIcon/>
            </IconButton>
            Stampa le ricevute
            <FormControl fullWidth margin="normal">
                <InputLabel id="select-type-label">Tipo di Ricevuta</InputLabel>
                <Select
                    labelId="select-type-label"
                    id="select-type"
                    value={selectedType}
                    onChange={handleTypeChange}
                >
                    <MenuItem value="Erogazioni Liberali">Erogazioni Liberali</MenuItem>
                    <MenuItem value="Tesseramento">Tesseramento</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="select-payment-status-label">Stato del Pagamento</InputLabel>
                <Select
                    labelId="select-payment-status-label"
                    id="select-payment-status"
                    value={selectedPaymentStatus}
                    onChange={handlePaymentStatusChange}
                >
                    <MenuItem value="Pagate">Pagate</MenuItem>
                    <MenuItem value="Non pagate">Non Pagate</MenuItem>
                </Select>
            </FormControl>
            <List>

                {filteredRicevute.map(ricevuta => {
                    const socio = soci.find(s => s.id === ricevuta.socio_id);
                    const fullName = socio ? `${socio.nome} ${socio.cognome}` : 'Socio non trovato';
                    const dataRicevuta = new Date(ricevuta.data).toLocaleDateString('it-IT');
                    const importo = selectedType === 'Tesseramento'
                        ? ricevuta.tesseramento + ricevuta.quota_corso_breve + ricevuta.prima_tranche + ricevuta.seconda_tranche
                        : ricevuta.importo_liberale;

                    return (
                        <ListItem key={ricevuta.id}>
                            <ListItemText
                                primary={`Ricevuta #${selectedType === 'Tesseramento' ? ricevuta.numero_ric : ricevuta.numero_liberale} - ${fullName} - ${dataRicevuta} ${ricevuta.da_pagare ? "  - da pagare: " + ricevuta.da_pagare : " - pagata"} `}
                                secondary={`Importo: €${importo}`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(ricevuta.id)}>
                                    <DeleteIcon/>
                                </IconButton>
                                <IconButton edge="end" aria-label="edit"
                                            onClick={() => navigate(`/ricevute/edit`, {
                                                state: {
                                                    ricevuta_id: ricevuta.id,
                                                    socio: socio
                                                }
                                            })}>
                                    <EditIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
}

export default RicevutaList;
