import React from 'react';
import SocioList from '../components/soci/SocioList';
import BasePage from "../components/BasePage";

function SocioListPage() {
    return (
        <BasePage titolo="Lista Soci">
            <SocioList />
        </BasePage>
    );
}

export default SocioListPage;
