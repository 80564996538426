import React, {useEffect, useState} from 'react';
import {Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {createRicevuta, downloadRicevuta, fetchRicevuta, fetchSoci, updateRicevuta,} from '../../utility/Api';
import {TipoRicevuta} from './TipoRicevuta';

function download(base64Data, nome) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = nome;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(downloadUrl);
    a.remove();
}


function RicevutaForm({socio, ricevuta_id}) {

    const [formData, setFormData] = useState({
        data: '',
        mezzo: '',
        tipo: '',
        socio_id: socio ? socio.id : '',
        tesseramento: '',
        da_pagare: 0,
        quota_corso_breve: '',
        prima_tranche: '',
        seconda_tranche: '',
        importo_liberale: '',
        intestatario: ''
    });

    useEffect(() => {
        async function loadRicevuta() {
            if (ricevuta_id) {
                const ricevuta = await fetchRicevuta(ricevuta_id)
                setFormData({
                    data: ricevuta.data || '',
                    mezzo: ricevuta.mezzo || '',
                    tipo: ricevuta.tipo || '',
                    socio_id: socio ? socio.id : '',
                    tesseramento: ricevuta.tesseramento || '',
                    da_pagare: ricevuta.da_pagare || 0,
                    quota_corso_breve: ricevuta.quota_corso_breve || '',
                    prima_tranche: ricevuta.prima_tranche || '',
                    seconda_tranche: ricevuta.seconda_tranche || '',
                    importo_liberale: ricevuta.importo_liberale || '',
                    intestatario: ricevuta.intestatario || ''
                })
            }
        }

        loadRicevuta()
    }, [ricevuta_id, socio]);

    const [soci, setSoci] = useState([]);
    const [selectedSocio, setSelectedSocio] = useState(1);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    useEffect(() => {
        if (socio) {
            setSelectedSocio(socio);
        }
    }, [socio]);
    useEffect(() => {
        async function loadSoci() {
            try {
                const sociData = await fetchSoci();
                setSoci(sociData.data);
            } catch (error) {
                console.error('Failed to fetch soci:', error);
            }
        }

        loadSoci();
    }, []);

    const handleChange = e => {
        const {name, value} = e.target;
        if (name === 'socio_id') {
            const selectedSocio = soci.find(s => s.id === value);
            setSelectedSocio(selectedSocio);
        }

        setFormData(prevFormData => {
            const updatedFormData = {...prevFormData, [name]: value};

            const importo = updatedFormData.tipo === 'Tesseramento'
                ? parseFloat(updatedFormData.tesseramento || 0) +
                parseFloat(updatedFormData.quota_corso_breve || 0) +
                parseFloat(updatedFormData.prima_tranche || 0) +
                parseFloat(updatedFormData.seconda_tranche || 0)
                : parseFloat(updatedFormData.importo_liberale || 0);

            if (["tesseramento", "quota_corso_breve", "prima_tranche", "seconda_tranche", "importo_liberale"].includes(name)) {
                return {...updatedFormData, da_pagare: importo};
            }

            return updatedFormData;
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        console.log(formData)
        if (ricevuta_id) {
            updateRicevuta(ricevuta_id, formData)
                .then(() => {
                    setSuccess('Ricevuta aggiornata correttamente.');
                    downloadRicevuta(ricevuta_id).then((response) => {
                        download(response.file, response.nome);
                    })
                })
                .catch((error) => {
                    setError('Failed to save ricevuta: ' + (error.response?.data?.message || error.message));
                });
        } else {
            createRicevuta(formData)
                .then((ricevuta) => {
                    setSuccess('Ricevuta salvata correttamente.');
                    downloadRicevuta(ricevuta.id).then((response) => {
                        download(response.file, response.nome)
                    })
                })
                .catch((error) => {
                    setError('Failed to save ricevuta: ' + (error.response?.data?.message || error.message));
                });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                {success && <Alert severity="success">{success}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                <FormControl fullWidth>
                    <InputLabel id="socio-id-label">Socio</InputLabel>
                    <Select
                        labelId="socio-id-label"
                        id="socio_id"
                        name="socio_id"
                        value={formData.socio_id}
                        onChange={handleChange}
                        fullWidth
                    >
                        {soci.map(socio => (
                            <MenuItem key={socio.id} value={socio.id}>
                                {socio.nome} {socio.cognome}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Data"
                    name="data"
                    type="date"
                    value={formData.data || ''}
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Mezzo"
                    name="mezzo"
                    select
                    value={formData.mezzo || ''}
                    onChange={handleChange}
                    fullWidth
                >
                    <MenuItem value="Bonifico">Bonifico</MenuItem>
                    <MenuItem value="Contanti">Contanti</MenuItem>
                </TextField>
                <TextField
                    label="Tipo"
                    name="tipo"
                    select
                    value={formData.tipo || ''}
                    onChange={handleChange}
                    fullWidth
                >
                    {Object.values(TipoRicevuta).map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                {formData.tipo === TipoRicevuta.TESSERAMENTO && (
                    <>
                        <TextField
                            label="Tesseramento"
                            name="tesseramento"
                            type="number"
                            value={formData.tesseramento || ''}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Quota Corso Breve"
                            name="quota_corso_breve"
                            type="number"
                            value={formData.quota_corso_breve || ''}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Prima Tranche"
                            name="prima_tranche"
                            type="number"
                            value={formData.prima_tranche || ''}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            label="Seconda Tranche"
                            name="seconda_tranche"
                            type="number"
                            value={formData.seconda_tranche || ''}
                            onChange={handleChange}
                            fullWidth
                        />
                    </>
                )}
                {formData.tipo === TipoRicevuta.EROGAZIONE_LIBERALE && (
                    <>
                        <FormControl fullWidth>
                            <InputLabel id="intestatario-label">Intestatario</InputLabel>
                            <Select
                                labelId="intestatario-label"
                                label="Intestatario"
                                name="intestatario"
                                value={formData.intestatario}
                                onChange={handleChange}
                                fullWidth
                            >
                                {selectedSocio.cf_gen_1 && (
                                    <MenuItem key={selectedSocio.cf_gen_1} value={selectedSocio.cf_gen_1}>
                                        {selectedSocio.nome_gen_1} {selectedSocio.cognome_gen_1}
                                    </MenuItem>)}
                                {selectedSocio.cf_gen_2 && (
                                    <MenuItem key={selectedSocio.cf_gen_2} value={selectedSocio.cf_gen_2}>
                                        {selectedSocio.nome_gen_2} {selectedSocio.cognome_gen_2}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Importo liberale"
                            name="importo_liberale"
                            type="number"
                            value={formData.importo_liberale || ''}
                            onChange={handleChange}
                            fullWidth
                        />
                    </>
                )}
                <TextField
                    label="Da pagare"
                    name="da_pagare"
                    type="int"
                    value={formData.da_pagare || ''}
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button type="submit" variant="contained" color="primary">
                    {ricevuta_id && "Modifica Ricevuta"}
                    {!ricevuta_id && "Aggiungi Ricevuta"}
                </Button>
            </form>
        </>
    );
}

export default RicevutaForm;
