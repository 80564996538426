import React, { useState } from 'react';
import SocioForm from '../components/soci/SocioForm';
import { fetchSocio } from '../utility/Api';
import BasePage from "../components/BasePage";

function SocioFormPage() {
    const [socio, setSocio] = useState(null);

    const handleSave = () => {
        fetchSocio(socio.id).then(setSocio);
    };

    return (
        <BasePage titolo="Socio Form">
            <SocioForm socio={socio} onSave={handleSave} />
        </BasePage>
    );
}

export default SocioFormPage;
