import React from 'react';
import {useNavigate} from 'react-router-dom';

function RenderNav(titolo) {
    titolo = titolo.titolo
    let navigate = useNavigate();
    const logoUrl = `${process.env.PUBLIC_URL}/logo.svg`;

    return <nav>
        <div className="nav-left">
            <img src={logoUrl} alt="Dreampuzzle Logo" onClick={() => navigate('/')}/>
        </div>
        <div className="nav-center">
            <h1 id="title" onClick={() => navigate('/')}>{titolo}</h1>
        </div>
        <div className="nav-right">
        </div>
    </nav>
}

export default RenderNav;
